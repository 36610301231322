'use strict';

(function () {
    var isSpDevice, isOnSp;
    isOnSp = function () {
        return window.location.pathname.match(/^\/sp/) !== null ? true : false;
    };
    isSpDevice = function () {
        var ua = navigator.userAgent;

        if (ua.indexOf('iPhone') > 0 || 
            ua.indexOf('iPod') > 0 || 
            ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0) {
            return true;

        } else if (ua.indexOf('iPad') > 0 || 
            ua.indexOf('Android') > 0){
            return true;

        } else {
            return false;
        }
    }

    if (isSpDevice() && !isOnSp()) {
        window.location.href = window.location.protocol + '//' +
                               document.domain +
                               '/sp' + 
                               window.location.pathname +
                               window.location.search;
        return;
    }

    if (!isSpDevice() && isOnSp()) {
        window.location.href = window.location.protocol + '//' +
                               document.domain +
                               window.location.pathname.replace(/^\/sp/, '') +
                               window.location.search;
        return;
    }
})();


